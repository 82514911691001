<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('log_detail')" :isFilter="false" />
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('log_detail')" :isFilter="false" />
			</template>
			<div class="row mb-5 align-items-center">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4" v-if="job.name">
					<h5>{{ job.name }}</h5>
					<b-progress :max="max" height="2rem">
						<b-progress-bar :value="value">
							<span>{{ $t('status') }}: <strong>{{ value }} / {{ max }}</strong></span>
						</b-progress-bar>
					</b-progress>
				</div>
				<div class="col-12 mt-4" v-if="job.name">
					<b-card bg-variant="light" :header="$t('log_detail')" class="text-left">
						<div class="row">
							<div class="col-12 col-sm-4 col-md-2 font-weight-bold">{{ $t('log_id') }}</div>
							<div class="col-12 col-sm-8 col-md-4  mb-4 mb-md-0">{{ logId }}</div>
							<div class="col-12 col-sm-4 col-md-2 font-weight-bold ">{{ $t('log_name') }}</div>
							<div class="col-12 col-sm-8 col-md-4  mb-4 mb-md-0">{{ job.name }}</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-4 col-md-2 font-weight-bold">{{ $t('log_time') }}</div>
							<div class="col-12 col-sm-8 col-md-4 mb-4 mb-md-0">{{ job.time }}</div>
							<div class="col-12 col-sm-4 col-md-2 font-weight-bold">{{ $t('log_status') }}</div>
							<div class="col-12 col-sm-8 col-md-4 mb-4 mb-md-0">{{ job.status }}</div>
						</div>
						<div class="row">
							<div class="col-12 col-sm-4 col-md-2 font-weight-bold">{{ $t('log_message') }}</div>
							<div class="col-12 col-sm-8 col-md-10">{{ job.msg }}</div>
						</div>
						<div class="row mt-5 d-flex">
								<button v-if="job.filename" type="button" @click="download" class="btn btn-primary">{{
									toUpperCase('download_result_file') }}</button>

                                <button v-if="job.failed_data" type="button" @click="downloadFailLog" class="btn btn-primary ml-2">{{
                                        toUpperCase('download_fail_log') }}</button>
						</div>
					</b-card>
				</div>
			</div>
			<div class="border p-5 rounded-sm " v-if="job.name">
				<b-tabs content-class="mt-3" justified>
					<b-tab :title="'Request Log (' + job.requestDataCount + ')'" active>
						<ul>
							<li v-for="(item, key) in job.requestData" :key="'request' + key">
								<span v-b-toggle="'request' + key">#{{ key + 1 }}</span>
								<b-collapse :id="'request' + key" accordion="my-accordion" role="tabpanel">
									<b-card-body>
										<b-card-text>{{ item }}</b-card-text>
									</b-card-body>
								</b-collapse>
							</li>
						</ul>
					</b-tab>
					<b-tab :title="'Success Log (' + job.successDataCount + ')'">
						<ul>
							<li v-for="(item, key) in job.successData" :key="'request' + key">
								<span v-b-toggle="'request' + key">#{{ key + 1 }}</span>
								<b-collapse :id="'request' + key" accordion="my-accordion" role="tabpanel">
									<b-card-body>
										<b-card-text>{{ item }}</b-card-text>
									</b-card-body>
								</b-collapse>
							</li>
						</ul>
					</b-tab>
					<b-tab :title="'Failed Log (' + job.failedDataCount + ')'">
						<ul>
							<li v-for="(item, key) in job.failedData" :key="'failed' + key">{{ item }}</li>
						</ul>
					</b-tab>
				</b-tabs>
			</div>
		</app-layout>
	</div>
</template>

<script>
// Services
import JobService from "@/services/JobService";

// Other
import moment from "moment";

export default {
	data() {
		return {
			max: 0,
			value: 0,
			logId: this.$route.params.log_id,
			job: {},
			polling: null
		}
	},
	metaInfo() {
		return {
			title: this.$t("log_detail")
		}
	},
	created() {
		this.getStatus();
		this.pollingData();
	},
	beforeDestroy() {
		clearInterval(this.polling);
	},
	methods: {
		getStatus() {
			JobService.get(this.logId)
				.then(response => {
					let data = response.data.data;
					this.job = data;
					this.job.name = data.name;
					this.max = data.request_data_count;
					this.value = data.failed_data_count + data.success_data_count;
					this.job.time = moment(data.updated_at).format('YYYY-MM-DD H:m:s');
					this.job.status = data.status;
					this.job.msg = data.message;
					this.job.filename = data.filename;

					this.job.requestData = data.request_data;
					this.job.requestDataCount = data.request_data_count;
					this.job.successData = data.success_data;
					this.job.successDataCount = data.success_data_count;
					this.job.failedData = data.failed_data;
					this.job.failedDataCount = data.failed_data_count;
					if (data.status == 'done') {
						clearInterval(this.polling);
					}
				})
		},
		pollingData() {
			this.polling = setInterval(() => {
				this.getStatus()
			}, 5000)
		},
		download() {
			if (this.checkPermission('joblog_download')) {
				JobService.download(this.logId)
					.then(response => {
						this._downloadFile(response, this.job.filename);
					})
					.catch(e => {
						this.toast(e.data);
					});
			} else {
				this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
			}
		},
        downloadFailLog() {
            if (this.checkPermission('joblog_download')) {
                JobService.downloadFailLog(this.logId)
                    .then(response => {
                        this._downloadFile(response, this.job.name+'-'+this.job.created_at+'.xlsx');
                    })
                    .catch(e => {
                        this.toast(e.data);
                    });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        }
	}
}
</script>
